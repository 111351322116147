(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/header/views/svsheader-centercontent.js') >= 0) return;  svs.modules.push('/components/components/header/views/svsheader-centercontent.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.header=_cmps.header||{};
_cmps.header.templates=_cmps.header.templates||{};
svs.components.header.templates.svsheader_centercontent = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"logoCssClass") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":1,"column":40},"end":{"line":1,"column":103}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    return " header-title-text";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"js-top-center-content header-title-content "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"row2") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":3,"column":60},"end":{"line":3,"column":146}}})) != null ? stack1 : "")
    + "\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "header-title-content-2-rows";
},"9":function(container,depth0,helpers,partials,data) {
    return "header-title-content-1-rows";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"href") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a class=\"js-top-center-content header-title-content "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"row2") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":6,"column":59},"end":{"line":6,"column":145}}})) != null ? stack1 : "")
    + "\" href=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"href") : depth0), depth0))
    + "\">\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a class=\"js-top-center-content header-title-content "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"row2") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":8,"column":59},"end":{"line":8,"column":145}}})) != null ? stack1 : "")
    + "\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"baseUrl") || (depth0 != null ? lookupProperty(depth0,"baseUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"baseUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":153},"end":{"line":8,"column":164}}}) : helper)))
    + "\">\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"isString")||(depth0 && lookupProperty(depth0,"isString"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"isString","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":49,"column":19}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = (lookupProperty(helpers,"setHeaderSize")||(depth0 && lookupProperty(depth0,"setHeaderSize"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"setHeaderSize","hash":{},"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":13,"column":32}}})) != null ? stack1 : "")
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"row1") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"row2") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifAny")||(depth0 && lookupProperty(depth0,"ifAny"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"row1") : depth0),(depth0 != null ? lookupProperty(depth0,"row2") : depth0),{"name":"ifAny","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(39, data, 0),"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":48,"column":18}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"logoCssClass") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":16,"column":10},"end":{"line":30,"column":17}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\"header-row-1 header-row-logo "
    + container.escapeExpression((lookupProperty(helpers,"setHeaderSize")||(depth0 && lookupProperty(depth0,"setHeaderSize"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"logoCssClass") : stack1),{"name":"setHeaderSize","hash":{},"data":data,"loc":{"start":{"line":17,"column":51},"end":{"line":17,"column":91}}}))
    + "\"></i>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"brand") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":29,"column":19}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isMobile","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":20,"column":14},"end":{"line":25,"column":27}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"header-row-1 header-row-logo\">"
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"alt":((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"alt") : stack1),"size":"100","outline":((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"brandOutline") : stack1),"emblem":true,"brand":((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"brand") : stack1)},"data":data,"loc":{"start":{"line":21,"column":59},"end":{"line":21,"column":168}}}))
    + "</span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"header-row-1 header-row-logo hidden-lg hidden-xl\">"
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"alt") : stack1),"size":"100","outline":((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"brandOutline") : stack1),"emblem":true,"brand":((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"brand") : stack1)},"data":data,"loc":{"start":{"line":23,"column":79},"end":{"line":23,"column":188}}}))
    + "</span>\n                <span class=\"header-row-1 header-row-logo visible-lg-inline-block visible-xl-inline-block\">"
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"alt") : stack1),"outline":((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"brandOutline") : stack1),"emblem":true,"brand":((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"brand") : stack1)},"data":data,"loc":{"start":{"line":24,"column":107},"end":{"line":24,"column":205}}}))
    + "</span>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span class=\"header-row-1 header-row-text hidden-lg hidden-xl\">"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"emblemDisabled") : stack1),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":77},"end":{"line":27,"column":216}}})) != null ? stack1 : "")
    + "<span class=\"header-title-content-text\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</span></span>\n              <span class=\"header-row-1 header-row-text visible-lg-inline-block visible-xl-inline-block\">"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"emblemDisabled") : stack1),{"name":"unless","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":105},"end":{"line":28,"column":245}}})) != null ? stack1 : "")
    + "<span class=\"header-title-content-text\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"row1") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</span></span>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"size":"30","brand":"vinnare"},"data":data,"loc":{"start":{"line":27,"column":113},"end":{"line":27,"column":154}}}))
    + "<span class=\"header-title-content-divider\"></span> ";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"size":"100","brand":"vinnare"},"data":data,"loc":{"start":{"line":28,"column":141},"end":{"line":28,"column":183}}}))
    + "<span class=\"header-title-content-divider\"></span> ";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"row2") : depth0)) != null ? lookupProperty(stack1,"logoCssClass") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":34,"column":10},"end":{"line":38,"column":17}}})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\"header-row-2 header-row-logo "
    + container.escapeExpression((lookupProperty(helpers,"setHeaderSize")||(depth0 && lookupProperty(depth0,"setHeaderSize"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"row2") : depth0)) != null ? lookupProperty(stack1,"logoCssClass") : stack1),{"name":"setHeaderSize","hash":{},"data":data,"loc":{"start":{"line":35,"column":51},"end":{"line":35,"column":91}}}))
    + "\"></i>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"header-row-2 header-row-text f-200\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"row2") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</span>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isMobile","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(42, data, 0),"data":data,"loc":{"start":{"line":42,"column":10},"end":{"line":47,"column":23}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"40":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"header-row-1 header-row-logo\">"
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"alt":"Svenska Spel","class":"header-special-edition","outline":false,"emblem":true,"brand":"svenskaspel-twolines"},"data":data,"loc":{"start":{"line":43,"column":55},"end":{"line":43,"column":175}}}))
    + "</span>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"hidden-lg hidden-xl\"><span class=\"header-row-1 header-row-logo\">"
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":"Svenska Spel","class":"header-special-edition","outline":false,"emblem":true,"brand":"svenskaspel-twolines"},"data":data,"loc":{"start":{"line":45,"column":89},"end":{"line":45,"column":209}}}))
    + "</span></span>\n            <span class=\"visible-lg-inline-block visible-xl-inline-block\"><span class=\"header-row-1 header-row-logo\">"
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":"Svenska Spel","size":"500","outline":false,"emblem":true,"brand":"svenskaspel-twolines"},"data":data,"loc":{"start":{"line":46,"column":117},"end":{"line":46,"column":217}}}))
    + "</span></span>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isMobile","hash":{},"fn":container.program(45, data, 0),"inverse":container.program(47, data, 0),"data":data,"loc":{"start":{"line":51,"column":6},"end":{"line":56,"column":19}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"45":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"header-row-1 header-row-logo\">"
    + container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"brands",{"name":"ui","hash":{"alt":"Svenska Spel","class":"header-special-edition","outline":false,"emblem":true,"brand":"svenskaspel-twolines"},"data":data,"loc":{"start":{"line":52,"column":51},"end":{"line":52,"column":171}}}))
    + "</span>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"hidden-lg hidden-xl\"><span class=\"header-row-1 header-row-logo\">"
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":"Svenska Spel","class":"header-special-edition","outline":false,"emblem":true,"brand":"svenskaspel-twolines"},"data":data,"loc":{"start":{"line":54,"column":85},"end":{"line":54,"column":205}}}))
    + "</span></span>\n        <span class=\"visible-lg-inline-block visible-xl-inline-block\"><span class=\"header-row-1 header-row-logo \">"
    + alias3((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"brands",{"name":"ui","hash":{"alt":"Svenska Spel","size":"500","outline":false,"emblem":true,"brand":"svenskaspel-twolines"},"data":data,"loc":{"start":{"line":55,"column":114},"end":{"line":55,"column":214}}}))
    + "</span></span>\n";
},"49":function(container,depth0,helpers,partials,data) {
    return "      </span>\n";
},"51":function(container,depth0,helpers,partials,data) {
    return "      </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"header-title"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"row1") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":1,"column":23},"end":{"line":1,"column":136}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"href") : depth0),"===","",{"name":"compare","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":10,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,depth0,{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":57,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"href") : depth0),"===","",{"name":"compare","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(51, data, 0),"data":data,"loc":{"start":{"line":58,"column":2},"end":{"line":62,"column":14}}})) != null ? stack1 : "")
    + "</h1>";
},"useData":true});
Handlebars.partials['components-components-header-svsheader_centercontent'] = svs.components.header.templates.svsheader_centercontent;
})(svs, Handlebars);


 })(window);